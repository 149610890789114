<template>
    <div class="card-body">
        <div class="card-body-title">Оплаты</div>
        <table class="paymentList table">
            <thead>
            <tr>
                <th>
                    <div class="t" @click="sortList('title')">Название</div>
                    <input class="title" name="title" v-model="filterParams.title">
                </th>
                <th class="media-only-desktop">
                    <div class="t" @click="sortList('status_id')">Стадия</div>
                    <select v-model="filterParams.status_id">
                        <option value="">Все</option>
                        <option v-for="option in statuses" :value="option.id">{{option.title}}</option>
                    </select>
                </th>
                <th class="media-only-desktop">
                    <div class="t" @click="sortList('status_dl')">Дедлайн стадии</div>
                    <input class="date" type="date" name="date" v-model="filterParams.status_dl">
                </th>
                <th class="media-only-desktop">
                    <div class="t" @click="sortList('user_name')">Пользователь</div>
                    <input class="user_name" name="user_name" v-model="filterParams.user_name">
                </th>
                <th class="media-only-desktop">
                    <div class="t" @click="sortList('claim_id')">Дело</div>
                    <input class="claim_id" name="claim_id" v-model="filterParams.claim_id">
                </th>
                <th class="media-only-desktop">
                    <div class="t" @click="sortList('direction_id')">Направление</div>
                    <select v-model="filterParams.direction_id">
                        <option value="">Все</option>
                        <option v-for="option in directions" :value="option.id">{{option.title}}</option>
                    </select>
                </th>
                <th class="media-only-desktop">
                    <div class="t" @click="sortList('type_id')">Тип</div>
                    <select v-model="filterParams.type_id">
                        <option value="">Все</option>
                        <option v-for="option in types" :value="option.id">{{option.title}}</option>
                    </select>
                </th>
                <th class="media-only-desktop">
                    <div class="t" @click="sortList('amount')">Сумма</div>
                    <input class="amount" v-model="filterParams.amount">
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in listFiltered" :key="item.id" :class="{ 'dl-red' : item.status_dl_datediff < 2 && item.status_id != 3, 'dl-yel': item.status_dl_datediff == 2 && item.status_id != 3, 'dl-green':item.status_id == 3}">
                <td>{{item.title}}</td>
                <td class="media-only-desktop">
                    <select @change="changeItem(item)" v-model="item.status_id" :disabled="!$store.state.isAdmin() && !$store.state.isImplementor() && !$store.state.isBuh()">
                        <option v-for="option in statuses" :value="option.id">{{option.title}}</option>
                    </select>
                </td>
                <td class="media-only-desktop">
                    <input @change="changeItem(item)" v-model="item.status_dl" type="date" :readonly="!$store.state.isAdmin() && !$store.state.isImplementor() && !$store.state.isBuh()"/>
                </td>
                <td class="media-only-desktop"><router-link :to="`/panel/user/${item.user_id}/view`">{{item.user_name}}</router-link></td>
                <td class="media-only-desktop"><router-link :to="`/panel/claim/${item.claim_id}/view`">#{{item.claim_id}}</router-link></td>
                <td class="media-only-desktop">{{item.direction_title}}</td>
                <td class="media-only-desktop">{{item.type_title}}</td>
                <td class="media-only-desktop">{{parseInt(item.amount).toLocaleString()}}</td>
                <td class="controls flex-st">
                    <div class="item delete" v-if="$store.state.isAdmin() || $store.state.isImplementor() || $store.state.isBuh()" @click="del(item)"><i class="material-icons">delete</i></div>
                    <router-link class="item view" :to="`/panel/payment/${item.id}/view`"><i class="material-icons">description</i></router-link>
                    <router-link class="item edit" :to="`/panel/payment/${item.id}/edit`"><i class="material-icons">edit</i></router-link>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "paymentList",
    props:{
        list: Array,
    },
    data() {
        return {
            directions:[],
            types:[],
            statuses:[],
            filterParams: {
                title:'',
                direction_id:'',
                type_id:'',
                status_id:'',
                status_dl:'',
                claim_id:'',
                user_name:'',
                amount:'',
            },
            listFiltered:[],
            sortParams: {
                field:'',
                dir:'asc'
            }
        }
    },
    watch: {
        filterParams: {
            handler(newVal, oldVal) {
                this.filter();
            },
            deep: true,
        },
        list:function (){
            this.filter();
        }
    },
    methods: {
        filter() {
            let res = this.$props.list;
            if (this.filterParams.status_id.length) {
                res = res.filter(item => item.status_id == this.filterParams.status_id)
            }
            if (this.filterParams.direction_id.length) {
                res = res.filter(item => item.direction_id == this.filterParams.direction_id)
            }
            if (this.filterParams.type_id.length) {
                res = res.filter(item => item.type_id == this.filterParams.type_id)
            }
            if (this.filterParams.title.length) {
                res = res.filter(item => item.title.toLowerCase().indexOf(this.filterParams.title.toLowerCase()) != -1)
            }
            if (this.filterParams.status_dl.length) {
                res = res.filter(item => item.status_dl.toLowerCase().indexOf(this.filterParams.status_dl.toLowerCase()) != -1)
            }
            if (this.filterParams.user_name.length) {
                res = res.filter(item => item.user_name.toLowerCase().indexOf(this.filterParams.user_name.toLowerCase()) != -1)
            }
            if (this.filterParams.claim_id.length) {
                res = res.filter(item => item.claim_id.toLowerCase().indexOf(this.filterParams.claim_id.toLowerCase()) != -1)
            }
            if (this.filterParams.amount.length) {
                res = res.filter(item => item.amount.toLowerCase().indexOf(this.filterParams.amount.toLowerCase()) != -1)
            }
            this.listFiltered = res;
        },
        sortList(field) {
            if(this.sortParams.field == field) {
                this.sortParams.dir = this.sortParams.dir == 'asc' ? 'desc' : 'asc';
            } else {
                this.sortParams.field = field;
            }
            let self = this;
            switch (this.sortParams.field) {
                case 'amount':
                    this.listFiltered.sort(function (a, b) {
                        let c = a;
                        if (self.sortParams.dir == 'desc') {
                            a = b;
                            b = c;
                        }
                        return parseInt(a.amount) - parseInt(b.amount);
                    })
                    break;
                case 'claim_id':
                    this.listFiltered.sort(function (a, b) {
                        let c = a;
                        if (self.sortParams.dir == 'desc') {
                            a = b;
                            b = c;
                        }
                        return parseInt(a.claim_id) - parseInt(b.claim_id);
                    })
                    break;
                case 'status_id':
                    this.listFiltered.sort(function (a, b) {
                        let c = a;
                        if (self.sortParams.dir == 'desc') {
                            a = b;
                            b = c;
                        }
                        return parseInt(a.status_id) - parseInt(b.status_id);
                    })
                    break;
                case 'direction_id':
                    this.listFiltered.sort(function (a, b) {
                        let c = a;
                        if (self.sortParams.dir == 'desc') {
                            a = b;
                            b = c;
                        }
                        return parseInt(a.direction_id) - parseInt(b.direction_id);
                    })
                    break;
                case 'title':
                    this.listFiltered.sort(function (a, b) {
                        let c = a;
                        if (self.sortParams.dir == 'desc') {
                            a = b;
                            b = c;
                        }
                        if (a.title > b.title) {
                            return 1;
                        }
                        if (a.title < b.title) {
                            return -1;
                        }
                        return 0;
                    })
                    break;
                case 'status_dl':
                    this.listFiltered.sort(function (a, b) {
                        let c = a;
                        if (self.sortParams.dir == 'desc') {
                            a = b;
                            b = c;
                        }
                        if (a.status_dl > b.status_dl) {
                            return 1;
                        }
                        if (a.status_dl < b.status_dl) {
                            return -1;
                        }
                        return 0;
                    })
                    break;
                case 'user_name':
                    this.listFiltered.sort(function (a, b) {
                        let c = a;
                        if (self.sortParams.dir == 'desc') {
                            a = b;
                            b = c;
                        }
                        if (a.user_name > b.user_name) {
                            return 1;
                        }
                        if (a.user_name < b.user_name) {
                            return -1;
                        }
                        return 0;
                    })
                    break;
            }
        },
        changeItem(item){
            this.$api.post(`claim/${item.claim_id}/payment/${item.id}`,item).then(res => {
                if (res.data.success) {
                    this.$emit('updated');
                    this.$store.commit('showNotify',{title:'Сохранено',content:'Оплата успешно изменена',type:'success'});
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        del(item) {
            this.$api.delete(`claim/${item.claim_id}/payment/${item.id}`).then(res => {
                if (res.data.success) {
                    this.$emit('updated');
                    this.$store.commit('showNotify',{title:'Удалено',content:'Оплата успешно удалена',type:'success'});
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getDirections() {
            this.$api.get(`payment/directions`, this.form).then(res => {
                if (res.data.success) {
                    this.directions = res.data.data;
                }
            })
        },
        getStatuses() {
            this.$api.get(`payment/stages`, this.form).then(res => {
                if (res.data.success) {
                    this.statuses = res.data.data;
                }
            })
        },
        getTypes() {
            this.$api.get(`payment/types`, this.form).then(res => {
                if (res.data.success) {
                    this.types = res.data.data;
                }
            })
        },
    },
    created() {
        this.getDirections();
        this.getStatuses();
        this.getTypes();
        this.filter();
    },
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";
.paymentList {
    .item {
        display: block;
        margin: 0 0 0 15px;
        cursor: pointer;
        &.edit {
            color: $cl-yellow;
        }
        &.delete {
            color: $cl-red;
        }
        &.view {
            color: $cl-blue;
        }
    }
    tr {
        &.dl-red {
            background: lighten($cl-red, 30);
        }
        &.dl-yel {
            background: lighten($cl-yellow, 10);
        }
        &.dl-green {
            background: lighten($cl-green, 50);
        }
    }
}
</style>