<template>
    <div class="user-edit">
        <div class="main-h1">Просмотр пользователя</div>
        <div class="card">
            <div class="card-body">
                <div class="card-body-title">Профиль</div>
                <table class="table">
                    <tr>
                        <td>Имя:</td>
                        <td><input v-model="data.name" readonly></td>
                    </tr>
                    <tr>
                        <td>Email:</td>
                        <td><input v-model="data.email" readonly></td>
                    </tr>
                    <tr>
                        <td>Номер телефона:</td>
                        <td><input v-model="data.phone" readonly></td>
                    </tr>
                    <tr>
                        <td>Реквизиты:</td>
                        <td><textarea v-model="data.requisite" readonly/></td>
                    </tr>
                    <tr v-if="data.roles">
                        <td>Роли:</td>
                        <td>
                            {{ data.roles.map((item) => item.title).join(', ') }}
                        </td>
                    </tr>
                    <tr v-if="data.relAgent">
                        <td>Связанный агент:</td>
                        <td>
                            {{ data.relAgent.name }}
                        </td>
                    </tr>
                    <tr v-if="data.relClaimants">
                        <td>Связанные истцы:</td>
                        <td>
                            {{ data.relClaimants.map((item) => item.name).join(', ') }}
                        </td>
                    </tr>
                </table>
            </div>
            <div class="card-body" v-if="claims.implementor">
                <div class="card-body-title">Исполнитель</div>
                <claim-list :list="claims.implementor"/>
            </div>
            <div class="card-body" v-if="claims.claimant">
                <div class="card-body-title">Истец</div>
                <claim-list :list="claims.claimant"/>
            </div>
            <div class="card-body" v-if="claims.defendant">
                <div class="card-body-title">Ответчик</div>
                <claim-list :list="claims.defendant"/>
            </div>
            <div class="card-body" v-if="claims.arbiter">
                <div class="card-body-title">Арбитр</div>
                <claim-list :list="claims.arbiter"/>
            </div>
            <div class="card-body" v-if="claims.agent">
                <div class="card-body-title">Агент</div>
                <claim-list :list="claims.agent"/>
            </div>
            <payment-list v-if="payments.length" :list="payments" @updated="getPayments"/>
        </div>
    </div>
</template>

<script>
import claimList from '../../../components/claim/list.vue';
import paymentList from '../../../components/payment/list'
export default {
    name: "userView",
    components: {claimList,paymentList},
    data() {
        return {
            data: {},
            claims: {},
            payments:[],
            relAgent:false,
            relClaimants:[],
        }
    },
    methods: {
        getData() {
            this.$api.get(`user/${this.$route.params.id}`).then(res => {
                if (res.data.success) {
                    this.data = res.data.data
                }
            })
        },
        getClaims() {
            this.$api.get(`user/${this.$route.params.id}/claims`).then(res => {
                if (res.data.success) {
                    this.claims = res.data.data
                }
            })
        },
        getPayments() {
            this.$api.get(`user/${this.$route.params.id}/payments`).then(res => {
                if (res.data.success) {
                    this.payments = res.data.data
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getRelClaimant(user_id) {
            this.$api.get(`user/${user_id}/rel_claimants`).then(res => {
                this.requestInProgress = false;
                if (res.data.success) {
                    this.data.relClaimants = res.data.data;
                }
            })
        },
        getRelAgents(user_id) {
            this.$api.get(`user/${user_id}/rel_agent`).then(res => {
                this.requestInProgress = false;
                if (res.data.success) {
                    this.data.relAgent = res.data.data;
                }
            })
        },
    },
    created() {
        this.getData();
        this.getClaims();
        this.getPayments();
        this.getRelClaimant(this.$route.params.id);
        this.getRelAgents(this.$route.params.id);
    }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables";
tr {
    &.dl-red {
        background: lighten($cl-red, 0);
        color: #fff;
        &:hover {
            color: $cl-text;
        }
    }
    &.dl-yel {
        background: lighten($cl-yellow, 0);
    }
}
.user-edit {

}
</style>